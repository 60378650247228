import React from 'react';
import { Link } from "react-router-dom";
import Button from "./registerbutton/navbtn";


function Navbar(props) {
  let navItem = props
    .name
    .map((item) => {
      return (
        <li key={item.id} className="nav-item">
          <Link to={item.link}>{item.name}</Link>
        </li>
      )
    })

    
  return (
    <nav className="navbar navbar-expand-lg header-title">
      <div className="container header-title">
        <Link className="navbar-brand" to="/">
          QUIERO SALIR
        </Link>
        
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            {navItem}
            <Link to="/invitation">
              <Button content="Invitación"></Button>
            </Link>
            
          </ul>

        </div>
      </div>
    </nav>
  );
}

export default Navbar;