import React from 'react';
import BannerText from './banner-text';
import CountDown from './bannerCountDown';

function banner(props) {

  return (
    <section style={props.bg} className="banner-part">
      <div className="overlay">
        <div className="container p-0">
          <BannerText err={props.text.error} sub={props.text.subHeading} heading={props.text.heading} />
          <div className="row">
                     <CountDown count={props.countdown}/>
          </div>

        </div>
      </div>
    </section>
  )
}

export default banner;