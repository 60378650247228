import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Button from "../navbar/registerbutton/navbtn";
import Festivetext from './festivetext';

function Festival(props) {
  const festiveSlider = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    centerMode: true,
    centerPadding: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }, {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 575.98,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  let Img = Array.isArray(props.img) ? 
    props.img.map(img => {
      return (<img key={img.id} src={img.img} alt={img.img}/>)
    }) : <img className="gallery-img" key={props.img.id} src={props.img.img} alt={props.img.img}/>;

let arr = props.year ? Array.from(props.year[0].year) : null;

const [width, setWidth] = useState(window.innerWidth);

function handleWindowSizeChange() {
    setWidth(window.innerWidth);
}
useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);

const isMobile = width <= 768;
    
  return (
    <div className="festive-part">
      <div className="container p-0">
        <div className="row mx-0 invitation-button-row">
            {isMobile && (
              <Link to="/invitation">
                <Button className="invitation-button" content="Obtener Invitación"></Button>
              </Link>
            )}
          <div className="col-lg-5">
            {Array.isArray(props.img) ? (
              <Slider {...festiveSlider}>
                {Img}
              </Slider>
            ) : (
              Img
            )} 
          </div>

          <div className="col-lg-7 p-0">
            <div className="festive-left">
            {props.year && 
              <div className="text-img">
                <h1>{arr[0]}{arr[1]}</h1>
                <h1>{arr[2]}{arr[3]}</h1>
                <p>{props.year[1].day}</p>
              </div>
            }
              <div className="text">
                <Festivetext text={props.text}/>
              </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Festival
