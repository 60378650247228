import React from 'react';
import { Link } from "react-router-dom";
function footer(props) {
  return (
    <section style={props.bg} className="footer-part">
      <div className="icon">
        <Link to="/fb">
          <i className="fa fa-facebook" aria-hidden="true"></i>
        </Link>
        <Link to="/twitter">
          <i className="fa fa-twitter" aria-hidden="true"></i>
        </Link>
      </div>
      <div className="fotermenu">
        <ul>
          <li>
          <Link to="/">Evento</Link>
          </li>
          {/* <li>
          <Link to="/rrpp">RRPPs</Link>
          </li> */}
          <li>
          <Link to="/invitation">
             Invitación
          </Link>
          </li>
        </ul>
      </div>
      <div className="copy">
        <span>&copy; Copyright 2023. All rights reserved by Ignacio Brugnoli</span>
      </div>
    </section>
  )
}
export default footer
