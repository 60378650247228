import React from 'react';
import Banner from '../components/banner/banner';
import Festival from '../components/festival/festival';
import Footer from '../components/footer/footer';
import Navbar from "../components/navbar/navbar";
import Alerta from '../components/popups/alert';
import { bannerCountdown, bannerText, bgimg, festivedate, festiveimg, festivetext, footerbg, image, location, navitem, videobtn } from '../components/variables/variable';
 function home() {
    return (
        <div>
          <Navbar name={navitem} logo={image}/>
          <Alerta />
          <Banner
            bg={bgimg}
            text={bannerText}
            countdown={bannerCountdown}
            videobtn={videobtn}/>
          <Festival img={festiveimg} year={festivedate} text={festivetext} location={location}/>
          {/* <Shedule  speaker={speaker} bg={shedulebg} title={title}/>
          <Upcoming title={upcomingtitle} event={event}/>
          <Gallery title={galleryTitle} img={galleryImg}/>
          <Event feedback={feedback} title={feedbackTitle}/>
          <Pricing title={pricingTitle} plan={pricingPlane} selectplan={selectplan}/>
          <Sponsor title={sponsorTitle} sponsor={sponsorimg}/> */}
          <Footer bg={footerbg}/>
        </div>
    )
}


export default home;