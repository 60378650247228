import React from 'react';




function festivetext(props) {
    let { details, details2, details3 } = props.text;
    return (
        <div>
            <h1 className="text-title">{props.text.heading}</h1>
            <p className="para1">{details}</p>
            {details2 && <p className="para1">{details2}</p>}
            {details3 && <p className="para2">{details3}</p>}
        </div>
    )
}



export default festivetext;

