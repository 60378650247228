import React from 'react';

import '../src/assets/css/about-page.css';
import '../src/assets/css/banner.css';
import '../src/assets/css/bootstrap.min.css';
import '../src/assets/css/comingsoon.css';
import '../src/assets/css/error-page.css';
import '../src/assets/css/event.css';
import '../src/assets/css/festiveslider.css';
import '../src/assets/css/footer.css';
import '../src/assets/css/gallery.css';
import '../src/assets/css/navbar.css';
import '../src/assets/css/navbutton.css';
import '../src/assets/css/pricing.css';
import '../src/assets/css/pricingpahe.css';
import '../src/assets/css/responsive.css';
import '../src/assets/css/shedule.css';
import '../src/assets/css/slick.css';
import '../src/assets/css/sponsor.css';
import '../src/assets/css/sponsorpage.css';
import '../src/assets/css/upcoming.css';
import '../src/assets/css/venobox.css';
import './App.css';

import Err from './pages/error';
import Home from './pages/home';
import Pricing from './pages/pricing_plane';


import { Route, BrowserRouter as Router, Switch } from "react-router-dom";




class App extends React.Component {
  render() {
    return ( 
        <Router>
          <Switch basename="/">
            <Route exact path="/" component={Home}></Route>
            {/* <Route exact path="/rrpp" component={Gallery}></Route> */}
            <Route exact path="/invitation" component={Pricing}></Route>
            <Route exact path="/error"  component={Err}></Route>
            <Route path='/twitter' component={() => {
              window.location.href = 'https://twitter.com/intent/tweet?text=No%20te%20lo%20podes%20perder...%20Morgan%20Night%20Club&url=https%3a%2f%2fquierosalir.club%2f&hashtags=nightlife,morganClub,ofLion,laVidaEsUna';
              return null;
            }}/>
            <Route path='/fb' component={() => {
              window.location.href = 'https://www.facebook.com/sharer.php?u=https://quierosalir.club&amp;t=No%20te%20lo%20pierdas%20...%20%20Morgan%20Night%20Club';
              return null;
            }}/>

          </Switch>
      </Router> 
    );
  }
}

export default App;