import axios from 'axios';
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";


import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";


function Form(props) {
  const history = useHistory();


  // form states
  const [name, setName]= useState('');
  const [lastName, setLastName]= useState('');
  const [phone, setPhone]= useState('');
  const [document, setDocument]= useState('');

  const [open, setOpen]= useState(false);
  const [status, setStatus]= useState(-1);

  // submit event
  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("Submit");

    const newData={
      Nombre: name,
      Apellido: lastName,
      Celular: phone,
      Documento: document
    }

    if (name === "" || lastName === "" || phone === "" || document === ""){
      console.log("Setting 2");
      setStatus(2);
      setOpen(true);
    } else {
      axios.get('https://sheet.best/api/sheets/05ecca26-6af8-46bc-995c-9321f219a00a').then((response) =>{
        let found= false;
        
        if (response.data.length > 0) {
          response.data.forEach(element => {
            if (element.Documento.replace(/[.\-]/g, "") === newData.Documento.replace(/[.\-]/g, ""))
              found= true;
          })
        }
        
        if(!found){
          axios.post('https://sheet.best/api/sheets/05ecca26-6af8-46bc-995c-9321f219a00a', newData).then((response)=>{
            console.log("Setting 0");
            setStatus(0);
            setOpen(true);
          });
        } else {
          console.log("Setting 1");
          setStatus(1);
          setOpen(true);
        }
      });
    }
  }

  const handleClose = () => {
    console.log("Handle close");
    if(status === 0){
      history.push("/");
    } else {
      console.log("Closing");
      setOpen(false);
    }
  };

  const popup = () => { return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle className='popup-title' id="responsive-dialog-title">
        {status === 0 ? "¡Genial!" : "¡Cuidado!"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className='popup-content'>
          {status === 0 ? "Su entrada ha sido reservada 💪💪" : 
            status === 1 ? "Ya hay una reserva con esta cedula" : 
            "Debe completar todos los datos"}
        </DialogContentText>
      </DialogContent>
      <Button onClick={handleClose} className="btn btn-success popup-actions">
        Okey 👌
      </Button>
    </Dialog>
  )};




  return (
    <form>
      <label className="heading">Datos personales & Entrada</label>
      <input 
        className="firstname ml" 
        type="text" 
        placeholder="Nombre" 
        required
        onChange={(e) => setName(e.target.value)}
        value={props.name}/>
      <input 
        className="firstname" 
        type="text"
        placeholder="Apellido" 
        required
        onChange={(e) => setLastName(e.target.value)}
        value={props.lastName}/>
      <input 
        className="email" 
        type="number" 
        placeholder="Celular" 
        required
        onChange={(e) => setPhone(e.target.value)}
        value={props.phone}/>
      <input 
        className="email" 
        type='text' 
        placeholder='Cedula' 
        required
        onChange={(e) => setDocument(e.target.value)}
        value={props.document}/>
      <button className="submit" onClick={(e) => handleSubmit(e)}>Obtener invitación</button>
      {popup()}
    </form>

  )
}

export default Form;
