import React from 'react';
import Footer from '../components/footer/footer';
import Navbar from "../components/navbar/navbar";
import Form from '../components/pricing/form/form';
import { aboutbgimg, footerbg, image, navitem, selectplan } from '../components/variables/variable';

function pricing_plane(props) {
  
  return (

    <section className="prcing-page">
      <Navbar name={navitem} logo={image}/>
      <nav aria-label="breadcrumb" style={aboutbgimg}>
        <div className="overlay">
          <h1>Invitaciónes de la Barra</h1>
          <h1>🦁 Of Lion 🦁</h1>
        </div>
      </nav>
      <div className="container">
        <Form  selectplan={selectplan} props={props}/>
      </div>
      
      <Footer bg={footerbg}/>
    </section>
  )
}

export default pricing_plane;